import { safeAtob } from '../safeAtob';
import { VEHICLE_STATUS } from 'constants/vehicle';

function transformVehicle(vehicle, transformerFunction) {
	return {
		...vehicle,
		best_offer_activity_id: transformerFunction(vehicle.best_offer_activity_id),
		best_offer_amount: transformerFunction(vehicle.best_offer_amount),
		best_offer_city: transformerFunction(vehicle.best_offer_city),
		best_offer_date: transformerFunction(vehicle.best_offer_date),
		best_offer_dealership_name: transformerFunction(vehicle.best_offer_dealership_name),
		best_offer_id: transformerFunction(vehicle.best_offer_id),
		best_offer_max_bid_amount: transformerFunction(vehicle.best_offer_max_bid_amount),
		best_offer_offerer_dealership_id: transformerFunction(vehicle.best_offer_offerer_dealership_id),
		best_offer_state: transformerFunction(vehicle.best_offer_state),
		total_offers: transformerFunction(vehicle.total_offers),
	};
}

export function decodeVehicle(vehicle) {
	switch (vehicle.bid_sale_auction_status) {
		case VEHICLE_STATUS.pending:
			return transformVehicle(vehicle, safeAtob);
		default:
			return {
				...vehicle,
			};
	}
}

export function encodeVehicle(vehicle) {
	switch (vehicle.bid_sale_auction_status) {
		case VEHICLE_STATUS.pending:
			return transformVehicle(vehicle, btoa);
		default:
			return {
				...vehicle,
			};
	}
}
