/* eslint-disable camelcase */
import dayjs from 'dayjs';
import { pricify } from 'helpers/common';
import { leadingOfferBubble, onOfferBubble } from '../bubbles';

const getBuyerText = (hasOffered, firm, masterOnBehalfOf, best_offer_dealership_name) => {
	if (masterOnBehalfOf) {
		return firm ? `${best_offer_dealership_name} (firm)` : best_offer_dealership_name;
	} else {
		if (firm) {
			return 'You (firm)';
		}

		return hasOffered ? 'You countered' : 'Your high bid';
	}
};

const getDealWorkingOfferStatus = (
	currentUserTopOfferer,
	bestOfferUserData,
	reserve_met,
	best_offer_amount,
	best_offer_date,
	lowest_counter_amount,
	lowest_counter_date,
	bid_sale_auction_ends_at,
	bid_sale_auction_extended_until,
	best_offer_comment,
	lowest_counter_comment,
	lowest_counter_firm,
	best_offer_firm,
	masterOnBehalfOf,
	best_offer_dealership_name,
) => {
	if (currentUserTopOfferer) {
		const sellerCounterOffer = !!lowest_counter_date;
		const buyerCounterOffer = dayjs(best_offer_date).isAfter(
			bid_sale_auction_extended_until || bid_sale_auction_ends_at,
		);

		if (sellerCounterOffer) {
			const sellerIsLastCounter = dayjs(lowest_counter_date).isAfter(best_offer_date);
			if (sellerIsLastCounter) {
				return {
					bidActions: true,
					counterInfo: {
						counterTitle: lowest_counter_firm ? 'Seller (firm)' : 'Seller countered',
						counterTitleAmount: pricify(lowest_counter_amount),
						counterTitleComment: lowest_counter_comment,
						counterTitleDate: lowest_counter_date,
						counterSubtitle: getBuyerText(
							buyerCounterOffer,
							best_offer_firm,
							masterOnBehalfOf,
							best_offer_dealership_name,
						),
						counterSubtitleAmount: pricify(best_offer_amount),
						counterSubtitleDate: best_offer_date,
						counterSubtitleComment: best_offer_comment,
						disableCounter: false,
						disableAccept: false,
					},
					hideReserveMet: true,
					unifiedStatusType: 'sellerIsLastCounter',
				};
			} else {
				return {
					bidActions: true,
					counterInfo: {
						counterTitle: getBuyerText(
							buyerCounterOffer,
							best_offer_firm,
							masterOnBehalfOf,
							best_offer_dealership_name,
						),
						counterTitleAmount: pricify(best_offer_amount),
						counterTitleComment: best_offer_comment,
						counterTitleDate: best_offer_date,
						counterSubtitle: lowest_counter_firm ? 'Seller (firm)' : 'Seller countered',
						counterSubtitleAmount: pricify(lowest_counter_amount),
						counterSubtitleDate: lowest_counter_date,
						counterSubtitleComment: lowest_counter_comment,
						disableCounter: true,
						disableAccept: true,
					},
					hideReserveMet: true,
					unifiedStatusType: 'buyerIsLastCounter',
				};
			}
		} else {
			return {
				bidActions: true,
				counterInfo: {
					counterTitle: getBuyerText(
						buyerCounterOffer,
						best_offer_firm,
						masterOnBehalfOf,
						best_offer_dealership_name,
					),
					counterTitleAmount: pricify(best_offer_amount),
					counterTitleComment: best_offer_comment,
					counterTitleDate: best_offer_date,
					counterSubtitle: `No seller counter bid yet`,
					disableCounter: buyerCounterOffer,
					disableAccept: true,
					noSellerCounter: true,
				},
				hideReserveMet: true,
				unifiedStatusType: 'buyerIsLastCounter',
			};
		}
	} else {
		return {
			...(reserve_met ? leadingOfferBubble : onOfferBubble),
			...bestOfferUserData,
			ended: true,
			hideReserveMet: true,
		};
	}
};

export default getDealWorkingOfferStatus;
