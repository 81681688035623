/* eslint-disable camelcase */
import { notifyError } from 'helpers/errorNotifier';
import { loggedUSerSelector } from 'store/slices/loggedUsers/selector';
import { getState } from 'store/store';
import { apiV3Headers } from 'helpers/restclient/headers';

export const NEW_JWT_AUTHENTICATION = true;

export const isNewJWTAuthenticationEnabled = () => NEW_JWT_AUTHENTICATION;

export const getAuthHeaders = ({ jwt, email }, master_on_behalf_of) => {
	const headers = {
		...apiV3Headers,
		'X-User-JWT': jwt,
		'X-User-Email': email,
	};

	if (master_on_behalf_of) {
		headers['X-On-Behalf-Of-Dealership'] = master_on_behalf_of;
	}

	return headers;
};

export const getAuthHeadersFromRedux = (masterOnBehalfOf) => {
	try {
		const user = loggedUSerSelector(getState());
		const { email, jwt } = user;

		return getAuthHeaders({ email, jwt }, masterOnBehalfOf);
	} catch (e) {
		notifyError(e, { metadata: { cause: 'error getAuthHeadersFromRedux' } });
		return {};
	}
};
