import { colors } from '@Backlot-Cars/archie';
import { VEHICLE_STATUS } from 'constants/vehicle';

export const leaderBubble = {
	text: 'Leader',
	background: colors.successLightest,
	borderColor: colors.success,
	showUserOffer: true,
	unifiedStatusType: 'leader',
};

export const outbidBubble = {
	text: 'Outbid',
	background: colors.alertLightest,
	borderColor: colors.alert,
	showUserOffer: true,
	unifiedStatusType: 'outbid',
};

export const leadingOfferBubble = {
	text: 'Leading offer',
	background: colors.smokeLight,
	borderColor: colors.grayLight,
	showUserOffer: true,
	unifiedStatusType: 'otherLeader',
};

export const onOfferBubble = {
	text: 'On offer',
	background: colors.smokeLight,
	borderColor: colors.grayLight,
	showUserOffer: true,
	unifiedStatusType: 'endedOnOffer',
};

export const soldBubble = {
	text: 'Sold',
	background: colors.white,
	borderColor: colors.grayLight,
	showUserOffer: true,
	unifiedStatusType: 'sold',
};

export const lostBubble = {
	text: 'Lost',
	background: colors.alertLightest,
	borderColor: colors.alert,
	showUserOffer: true,
	unifiedStatusType: 'lost',
};

export const winnerBubble = {
	text: 'Won',
	background: colors.white,
	borderColor: colors.success,
	showUserOffer: true,
	unifiedStatusType: 'won',
};

export const topBidBubble = {
	text: 'Top bid',
	background: colors.smokeLight,
	borderColor: colors.grayLight,
	showUserOffer: true,
	unifiedStatusType: 'otherLeader',
};

export const startingBidBubble = {
	text: 'Starting bid',
	showStartingBid: true,
	unifiedStatusType: 'awaitingBids',
};

export const userOfferOnPendingStatusBubble = {
	text: '',
	background: colors.white,
	borderColor: colors.grayLight,
	showUserOffer: true,
	unifiedStatusType: 'nonStartedBuyerBid',
};

export const getLiveOvertimeBubble = (currentUserTopOfferer, currentUserHasOffered) => {
	if (currentUserTopOfferer) {
		return leaderBubble;
	} else if (currentUserHasOffered) {
		return outbidBubble;
	}
	return topBidBubble;
};

export const getTotalOffersObject = (bidSaleAuctionStatus, totalOffers, lockedBidHistory) => ({
	showTotalOffersButton: bidSaleAuctionStatus !== VEHICLE_STATUS.pending && (totalOffers || lockedBidHistory),
});
