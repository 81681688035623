import { createAsyncThunk } from '@reduxjs/toolkit';
import BidSalesService from 'services/timedSales/buyside/bidsales.service';
import { calculateSummaryFromList } from 'store/slices/offerSummary/helper';

export const loadBuyerInitialOfferSummary = createAsyncThunk('loadBuyerInitialOfferSummary', async ({ loggedUser }) => {
	const { dealership_id: dealershipId, dealerships_where_user_is_master_buyer: dealershipsWhereUserIsMasterBuyer } =
		loggedUser;
	const { data } = await BidSalesService.instance.offerRefresh({
		vehicles: [],
		loggedUser,
		focussedSearchListKey: 'auction',
		reason: 'first_load',
		includeSummary: true,
	});

	const dealershipsId =
		!dealershipsWhereUserIsMasterBuyer?.length ? [dealershipId] : dealershipsWhereUserIsMasterBuyer;

	const summary = data.data.summary;

	return {
		bidSalesArr: summary,
		stats: calculateSummaryFromList({ bidSalesThatUserHasOfferer: summary, dealershipsId }),
		dealershipsId,
	};
});
