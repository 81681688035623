/* eslint-disable camelcase */
import { useState, useEffect } from 'react';
import { loggedUSerSelector } from 'store/slices/loggedUsers/selector';
import { getState } from 'store/store';

const whiteList = [
	'/redemption_authentication',
	'/redemption_verification',
	'/password/reset',
	'/sign_in',
	'/afc/welcome',
];

const useValidateSegment = () => {
	const [enableSegment, setEnableSegment] = useState(false);

	const user = loggedUSerSelector(getState());

	useEffect(() => {
		const validateSegment = () => {
			let enable = Boolean(user?.id) && !window?.analytics;
			if (!enable) {
				enable = whiteList.some((url) => window.location.href.includes(url));
			}

			if (enable) {
				setEnableSegment(true);
			}
		};

		validateSegment();
	}, [user]);

	return enableSegment;
};

export default useValidateSegment;
