export const getDealershipUrl = (dealershipId) => `${process.env.RELATIVE_BASE_URL}/dealerships/${dealershipId}`;
export const getCheckoutUrl = () => `${process.env.RELATIVE_BASE_URL}/checkouts/edit`;
export const getSellersManageOfferPage = (vehicleId) =>
	`${process.env.RELATIVE_BASE_URL}/inventory/vehicles/${vehicleId}/offers`;

export const getVdpURL = (vehicleId) => {
	return `/vehicle_detail/${vehicleId}`;
};

export const getSignInUrl = (referer) => `/sign_in${referer ? `?referer=${encodeURIComponent(referer)}` : ''}`;
export const getSearchUrlForMakeAndModal = ({ make, model }) => `/search?keywords=${make}%20${model}`;

export const getAFCWelcomePageUrl = (referer) => {
	return `/afc/welcome${referer ? `?referer=${encodeURIComponent(referer)}` : ''}`;
};

export const OPENLANE_SUPPORT_EMAIL = 'us.support@openlane.com';
export const EXTERNAL_LANDING_URL = 'https://www.openlane.com';
