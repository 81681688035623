export const isCurrentUserTheTopOfferer = ({
	currentDealershipOrAnySubordinateIdThatOffered,
	best_offer_offerer_dealership_id,
}) => {
	return (
		best_offer_offerer_dealership_id &&
		Number(currentDealershipOrAnySubordinateIdThatOffered) === Number(best_offer_offerer_dealership_id)
	);
};

export const isMasterBuyerInBehalfOf = ({ currentDealershipOrAnySubordinateIdThatOffered, loggedUser }) => {
	return currentDealershipOrAnySubordinateIdThatOffered !== loggedUser.dealership_id ?
			currentDealershipOrAnySubordinateIdThatOffered
		:	null;
};
